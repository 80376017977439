import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import t from 'tcomb-form'

import AuthBoxDoubleColumn from '../../UIComponents/AuthboxWithPass/AuthBox'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import Button from 'src/UIComponents/Button/ButtonDefault'
import FormZ from 'src/UIComponents/FormZ/FormZ'
import PasswordValidation from 'src/UIComponents/PasswordValidation/PasswordValidation'
import PasswordFactory from 'src/Components/FormFactories/PasswordFactory'
import formValidators from 'src/Utils/formValidators'
import { getQuerystring } from 'src/Helpers/Router/Tools'
import { verifyToken } from 'src/Actions/authentication'
import { updateUserPassword, resetUser } from 'src/Actions/user'
import { darkGray, bigText, largeMargin } from 'src/Styles/settings/Constants'
import AuthStatusMessage from 'src/UIComponents/AuthBox/AuthStatusMessage'
import {
  getPasswordValidation,
  getFormOptionsWithValidation
} from 'src/Utils/FormUtils'
import AlertMessage from '../../UIComponents/AlertMessage/AlertMessage'
import RecoverPasswordTemplate from '../../UIComponents/TemplatesForm/RecoverPasswordTemplate'

const RecoverPassword = () => {
  const dispatch = useDispatch()

  const {
    tokenValidateError,
    updatePassSuccess,
    updatePassError,
    updatePassRequesting
  } = useSelector(({ authentication, user }) => ({
    tokenValidateError: authentication.tokenValidateError,
    updatePassSuccess: user.updatePassSuccess,
    updatePassError: user.updatePassError,
    updatePassRequesting: user.updatePassRequesting
  }))

  const [showValidation, setShowValidation] = useState(false)

  const [userAuthInfo, setUserAuthInfo] = useState({
    username: null,
    token: null,
    userId: null
  })

  const [formValues, setFormValues] = useState({
    password: '',
    passwordConfirmation: '',
    isValid: false
  })

  useEffect(() => {
    const querystring = getQuerystring()
    setUserAuthInfo({
      token: querystring.t,
      username: querystring.u,
      userId: querystring.i
    })
    dispatch(verifyToken(querystring.t))
    dispatch(resetUser())
  }, [])

  const changePassword = (values) => {
    const { isValid } = getPasswordValidation(
      values.password,
      values.passwordConfirmation
    )
    setFormValues({
      ...formValues,
      ...values,
      isValid: isValid
    })
  }

  const submitPassword = () => {
    const isFormValid = t.validate(formValues, struct).isValid()

    if (isFormValid && formValues.isValid) {
      const passwordData = {
        current_password: userAuthInfo.token,
        new_password: formValues.password
      }

      dispatch(resetUser())
      dispatch(
        updateUserPassword(
          userAuthInfo.userId,
          passwordData,
          userAuthInfo.token
        )
      )
    } else {
      setShowValidation(true)
    }
  }

  const formOptions = {
    values: {
      password: formValues.password,
      passwordConfirmation: formValues.passwordConfirmation
    },
    options: {
      template: (locals) => RecoverPasswordTemplate(locals),
      fields: {
        password: {
          label: 'Senha',
          factory: PasswordFactory,
          autocomplete: false,
          config: {
            id: 'password'
          }
        },
        passwordConfirmation: {
          label: 'Confirmar Senha',
          factory: PasswordFactory,
          autocomplete: false,
          config: {
            id: 'passwordConfirmation'
          }
        }
      }
    }
  }

  const struct = t.struct({
    password: formValidators.Password,
    passwordConfirmation: formValidators.Password
  })

  const passwordValidation = getPasswordValidation(
    formValues.password,
    formValues.passwordConfirmation
  )

  return (
    <Fragment>
      {tokenValidateError ? (
        <Redirect to='token-invalido?origin=recuperar-senha' />
      ) : updatePassSuccess ? (
        <AuthStatusMessage
          type='success'
          path='/login'
          labelButton='Fazer login'
          message='Perfeito! Senha alterada com sucesso.'
        />
      ) : (
        <AuthBoxDoubleColumn focusTitle='Alterar' title={'Senha'}>
          <Fragment>
            <Grid.Col auto>
              <Label color={darkGray} fontSize={bigText} bold>
                PARA <Label fontSize={bigText}>ALTERAR SUA SENHA</Label> SIGA OS
                PASSOS ABAIXO:
              </Label>
              <PasswordValidation {...passwordValidation} />
            </Grid.Col>
          </Fragment>
          <Grid.Col>
            {updatePassError && (
              <AlertMessage noCode type='error' error={updatePassError} />
            )}
            <Grid.Row>
              <Label color={darkGray} fontSize={bigText} bold>
                {userAuthInfo.username}
              </Label>
            </Grid.Row>
            <Grid noPadding marginTop={largeMargin} marginBottom={largeMargin}>
              <FormZ
                options={
                  showValidation
                    ? getFormOptionsWithValidation(
                        formOptions.options,
                        formValues,
                        struct
                      )
                    : formOptions.options
                }
                struct={struct}
                values={formOptions.values}
                onChange={changePassword}
              />
            </Grid>
            <Grid.Row>
              <Grid.Col offset={9} cols={7}>
                <Button
                  isLoading={updatePassRequesting}
                  onClick={submitPassword}
                >
                  Alterar Senha
                </Button>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </AuthBoxDoubleColumn>
      )}
    </Fragment>
  )
}

export default RecoverPassword

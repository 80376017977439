import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { listBankAccounts, removeBankAccount } from 'src/Actions/bankAccounts'
import { getReceivingPolicy, getDefaultBankReceiver } from 'src/Actions/recipientMeans'
import Grid from 'src/UIComponents/Grid/Grid'
import TitleInfoPage from 'src/UIComponents/Title/TitleInfoPage'
import Title from 'src/UIComponents/Title/Title'
import { Title as Helmet } from 'src/Helpers/WhiteLabel'
import LineCard from 'src/UIComponents/LineCard/LineCard'
import BankAccountListItem from 'src/UIComponents/RecipientMeanListItem/BankAccountListItem'
import DigitalAccountListItem from 'src/UIComponents/RecipientMeanListItem/DigitalAccountListItem'
import Can from '../../Helpers/Permission/Can'
import { green, lightestGray } from 'src/Styles/settings/Constants'
import { setDefaultBankCredit } from '../../Actions/recipientMeans'
// import { updateReceivingPolicy } from '../../Actions/bankAccounts'
import BankAccountActiveItem from 'src/UIComponents/RecipientMeanListItem/BankAccountActiveItem'
import SubTitle from '../../UIComponents/Title/SubTitle'
import Label from '../../UIComponents/Label/Label'
import Button from '../../UIComponents/Button/ButtonDefault'
import Card from '../../UIComponents/Card/Card'
import AddBankAccountForm from './AddBankAccountForm'
import styled from 'styled-components'

import { darkGray, normalText } from '../../Styles/settings/Constants'
import { useGetPermissionProfile } from '../../Hooks/useGetPermissionProfile'
import { getApiSettings } from '../../Actions/settings'
import { MFASingleComponent } from '../MFA/mfaSingleComponent'

const BankAccountsComponent = ({ sellerView, ...props }) => {
  const [isRequestingId, setIsRequestingId] = useState(null)
  const [submitDialog, setSubmitDialog] = useState('')
  const [deleteDialog, setDeleteDialog] = useState('')
  const [showAddBankAccount, setShowAddBankAccount] = useState(false)
  const [showMFA, setShowMFA] = useState(false)
  const [showMFADelete, setShowMFADelete] = useState(false)
  const [bankAccountIdMFA, setBankAccountIdMFA] = useState('')

  const dispatch = useDispatch()

  const { getProfile } = useGetPermissionProfile()
  const profile = getProfile()

  const {
    bankAccountReceiver,
    isBankAccountsRequesting,
    bankAccounts,
    bankAccountReceiverRequesting,
    receivingPolicy,
    getReceivingPolicySuccess,
    sellerContext,
    customer,
    isDeleteBankAccountRequesting,
    // updateReceivingPolicyRequest,
    getReceivingPolicyRequesting,
    isRequesting,
    disableBankAccountOperation,
    createBankAccountSuccess
  } = useSelector(({ recipientMeans, marketplace, settings, context }) => ({
    createBankAccountSuccess: recipientMeans.createBankAccountSuccess,
    bankAccountReceiver: recipientMeans.bankAccountReceiver,
    isBankAccountsRequesting: recipientMeans.isBankAccountsRequesting,
    bankAccounts: recipientMeans.bankAccounts,
    bankAccountReceiverRequesting: recipientMeans.bankAccountReceiverRequesting,
    receivingPolicy: recipientMeans.receivingPolicy,
    getReceivingPolicySuccess: recipientMeans.getReceivingPolicySuccess,
    sellerContext: context.seller,
    customer: marketplace.details.customer,
    isRequesting: recipientMeans.updateDefaultCreditRequest,
    isDeleteBankAccountRequesting: recipientMeans.isDeleteBankAccountRequesting,
    updateReceivingPolicyRequest: recipientMeans.updateReceivingPolicyRequest,
    getReceivingPolicyRequesting: recipientMeans.getReceivingPolicyRequesting,
    disableBankAccountOperation: settings.items.disableBankAccountOperation
  }))

  const seller = sellerContext || customer

  useEffect(() => {
    dispatch(getApiSettings(true))
    if (sellerView && sellerContext) {
      dispatch(listBankAccounts(sellerContext.id))
      dispatch(getDefaultBankReceiver(sellerContext.id))
      dispatch(getReceivingPolicy(sellerContext.id))
    } else if (!sellerView) {
      dispatch(listBankAccounts(customer.id))
      dispatch(getDefaultBankReceiver(customer.id))
      dispatch(getReceivingPolicy(customer.id))
    }
  }, [createBankAccountSuccess, sellerContext, customer])

  useEffect(() => {
    setShowAddBankAccount(false)
  }, [bankAccounts])

  const activeAccount = bankAccounts.find(bankAccount => bankAccount.id === bankAccountReceiver)

  const digitalAccount = () => {
    if (seller.type === 'business') {
      const {
        owner: {
          first_name: firstName,
          last_name: lastName,
          taxpayer_id: taxpayerId
        }
      } = seller

      return {
        name: `${firstName} ${lastName}`,
        taxpayerId
      }
    } else {
      const {
        first_name: firstName,
        last_name: lastName,
        taxpayer_id: taxpayerId
      } = seller

      return {
        name: `${firstName} ${lastName}`,
        taxpayerId
      }
    }
  }

  const transferEnabled = receivingPolicy && receivingPolicy.transfer_enabled

  const isDigitalAccount = !getReceivingPolicyRequesting && getReceivingPolicySuccess && !transferEnabled
  const isBankAccount = transferEnabled && !!activeAccount

  const anyActiveAccount = isBankAccount || isDigitalAccount

  const getActiveAccountTypeLabel = () => {
    if (isDigitalAccount) return 'Conta Digital Ativa'
    else if (isBankAccount) return 'Conta Bancária Ativa'
    else return ''
  }

  const getActiveAccountLabelIcon = () => {
    if (isDigitalAccount) {
      return {
        src: '/assets/icons/banking/digital-account.svg',
        width: '18px',
        height: 'auto'
      }
    } else if (isBankAccount) {
      return {
        src: '/assets/icons/banking/bank-account.svg',
        width: '18px',
        height: 'auto'
      }
    } else return {}
  }

  const filteredBankAccounts = bankAccounts.filter(bankAccount => {
    const { id, bank_code: bankCode } = bankAccount

    return transferEnabled ? bankCode !== '10001' && id !== bankAccountReceiver : bankCode !== '10001'
  })

  const handleActivateBankAccount = (bankAccountId) => {
    setShowMFA(true)
    setBankAccountIdMFA(bankAccountId)
  }

  // const handleActivateDigitalAccount = () => {
  //   dispatch(updateReceivingPolicy(false, seller.id))
  // }

  const handleDeleteBankAccount = bankAccountId => {
    setShowMFADelete(true)
    setBankAccountIdMFA(bankAccountId)
  }

  const handleOpenSubmitDialog = bankAccountId => {
    setSubmitDialog(bankAccountId)
    setDeleteDialog('')
  }

  const handleOpenDeleteDialog = bankAccountId => {
    setSubmitDialog('')
    setDeleteDialog(bankAccountId)
  }

  const resetDialogs = () => {
    setSubmitDialog(null)
    setDeleteDialog(null)
    setIsRequestingId(null)
  }

  const ActiveItem = () => {
    if (isBankAccount) {
      return <BankAccountActiveItem
        data-tracking={`${props?.['data-tracking']}`}
        bankAccount={activeAccount} />
    }
    if (isDigitalAccount && !sellerView) {
      return <DigitalAccountListItem
        data-tracking={`${props?.['data-tracking']}`}
        digitalAccount={digitalAccount()}
        hasActions={false} />
    }
    return <Fragment />
  }

  const getExplanation = () => {
    if (isBankAccount) {
      return 'Você está recebendo seus markups automáticamente na sua conta ' +
        'bancária de acordo com o calendário de recebimento.'
    }
    if (isDigitalAccount) {
      return 'Você está recebendo seus markups automáticamente na sua conta ' +
        'digital de acordo com o calendário de recebimento.'
    }
  }

  const bankAccountListRequesting = bankAccountReceiverRequesting || isBankAccountsRequesting || getReceivingPolicyRequesting

  const showBankAccountsList = bankAccountListRequesting || filteredBankAccounts.length > 0

  const hasActions = profile !== 'full_visualization'

  const activateBank = () => {
    dispatch(setDefaultBankCredit(bankAccountIdMFA, seller))
    setIsRequestingId(bankAccountIdMFA)
  }

  const deactivateBank = () => {
    dispatch(removeBankAccount(bankAccountIdMFA))
    setIsRequestingId(bankAccountIdMFA)
  }

  const renderModal = () => {
    if (showMFA) {
      return <MFASingleComponent showComponent={setShowMFA} onSubmit={activateBank} />
    }
    if (showMFADelete) {
      return <MFASingleComponent showComponent={setShowMFADelete} onSubmit={deactivateBank} />
    }
  }

  return (
    <>
      {renderModal()}
      <Grid noPadding>
        <Grid.Row>
          <Grid.Col offset={sellerView ? 0 : 1} cols={sellerView ? 16 : 15}>
            {!sellerView && (
              <Fragment>
                <Helmet>Meios de Recebimento</Helmet>
                <TitleInfoPage offset={0}>Configurações do marketplace {'>'} </TitleInfoPage>
              </Fragment>
            )}
            <Grid.Row middleMarginBottom auto>
              <Title big noPadding data-test='config-meiosRecebimento-title'>
                <strong>Meios de Recebimento</strong>
              </Title>
              <Can do='create' on='BankAccount'>
                {!disableBankAccountOperation && !showAddBankAccount && (
                  <CreateBankAccountButtonWrapper>
                    <Button
                      data-tracking={`${props['data-tracking']} > Conta bancaria > Cadastrar`}
                      widthAuto
                      onClick={() => {
                        setShowAddBankAccount(true)
                      }}
                      inversed
                      data-test='config-meiosRecebimento-cadastrar-btn'
                    >
                    Cadastrar Conta Bancária
                    </Button>
                  </CreateBankAccountButtonWrapper>
                )}
              </Can>
            </Grid.Row>
            <Can do='create' on='BankAccount'>
              {showAddBankAccount && (
                <Grid.Row largeMarginBottom auto>
                  <Card
                    data-tracking={`${props['data-tracking']} > Conta bancaria > Cadastrar`}
                    buttonClose
                    handleClose={() => {
                      setShowAddBankAccount(false)
                    }}>
                    <Grid.Row auto>
                      <Grid.Col>
                        <Grid.Row smallMarginBottom>
                          <SubTitle themeColor>Cadastrar <strong>Conta Bancária</strong></SubTitle>
                        </Grid.Row>
                        <Grid.Row auto>
                          <Label fontSize={normalText} color={darkGray}>Você só pode cadastrar contas bancárias do mesmo titular do CNPJ desde marketplace</Label>
                        </Grid.Row>
                      </Grid.Col>
                    </Grid.Row>
                    <AddBankAccountForm seller={seller} />
                  </Card>
                </Grid.Row>
              )}
            </Can>
            {(anyActiveAccount || bankAccountListRequesting) && (
              <Grid.Row smallMarginBottom largeMarginBottom={bankAccountListRequesting} auto>
                <LineCard
                  isLoading={bankAccountListRequesting}
                  backgroundColor={green}
                  label={getActiveAccountTypeLabel()}
                  status='success'
                  icon={getActiveAccountLabelIcon()}>
                  <Grid noPadding >
                    <ActiveItem />
                  </Grid>
                </LineCard>
              </Grid.Row>
            )}
            {!bankAccountListRequesting && anyActiveAccount && (
              <Grid.Row auto largeMarginBottom>
                <Label color={darkGray}>
                  {getExplanation()}
                </Label>
              </Grid.Row>
            )}
            {
              (showBankAccountsList || !isDigitalAccount) && <Grid.Row largeMarginBottom auto>
                <Grid noPadding>
                  <Grid.Row auto middleMarginBottom>
                    <SubTitle line>Meios de Recebimento <strong>Cadastrados</strong></SubTitle>
                  </Grid.Row>
                  <Grid.Row auto>
                    <Grid noPadding>
                      {/* {
                      !isDigitalAccount && (
                        <Grid.Row auto bigMarginBottom>
                          <LineCard
                            isLoading={bankAccountReceiverRequesting || isBankAccountsRequesting || getReceivingPolicyRequesting || getReceivingPolicyRequesting}
                            backgroundColor={lightestGray}
                            label='Conta Digital'
                            status='neutral'
                            icon={{
                              src: '/assets/icons/banking/digital-account.svg',
                              width: '18px',
                              height: 'auto'
                            }}>
                            <Grid noPadding>
                              <DigitalAccountListItem
                                data-tracking={`${props['data-tracking']}`}
                                onSubmit={handleActivateDigitalAccount}
                                onOpenSubmitDialog={setSubmitDialog}
                                onCloseDialog={resetDialogs}
                                submitDialogActive={submitDialog === 'digitalAccount'}
                                digitalAccount={digitalAccount()}
                                disableActions={isRequesting}
                                hasActions={hasActions}
                                isLoading={updateReceivingPolicyRequest}
                              />
                            </Grid>
                          </LineCard>
                        </Grid.Row>
                      )
                    } */}
                      {showBankAccountsList && (
                        <Grid.Row auto bigMarginBottom>
                          <LineCard
                            isLoading={bankAccountListRequesting}
                            backgroundColor={lightestGray}
                            label='Conta Bancária'
                            status='neutral'
                            icon={{
                              src: '/assets/icons/banking/bank-account.svg',
                              width: '18px',
                              height: 'auto'
                            }}>
                            <Grid noPadding>
                              {
                                filteredBankAccounts.map((bankAccount, i) => (
                                  <BankAccountListItem
                                    data-tracking={`${props['data-tracking']}`}
                                    key={i}
                                    bankAccount={bankAccount}
                                    submitDialogActive={submitDialog === bankAccount.id}
                                    deleteDialogActive={deleteDialog === bankAccount.id}
                                    onCloseDialog={resetDialogs}
                                    onOpenSubmitDialog={handleOpenSubmitDialog}
                                    onOpenDeleteDialog={handleOpenDeleteDialog}
                                    onSubmit={handleActivateBankAccount}
                                    onDelete={handleDeleteBankAccount}
                                    disableActions={isRequesting}
                                    hasActions={!disableBankAccountOperation && hasActions}
                                    isLoading={(isRequesting && bankAccount.id === isRequestingId) || (isDeleteBankAccountRequesting && bankAccount.id === isRequestingId)}
                                  />
                                ))
                              }
                            </Grid>
                          </LineCard>
                        </Grid.Row>
                      )}
                      {/* This fixes box-shadow not showing up by adding a bottom margin to the last Row */}
                      {sellerView && (
                        <Grid.Row />
                      )}
                    </Grid>
                  </Grid.Row>
                </Grid>
              </Grid.Row>
            }
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </>
  )
}

const CreateBankAccountButtonWrapper = styled.div`
  width: 196px;
  display: flex;
  justify-self: flex-end;
`

BankAccountsComponent.propTypes = {
  sellerView: PropTypes.bool,
  'data-tracking': PropTypes.string
}

export default BankAccountsComponent

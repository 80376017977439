import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import t from 'tcomb-form'
import { Redirect } from 'react-router-dom'

import { updateUserPassword, resetUser } from 'src/Actions/user'
import { getQuerystring } from 'src/Helpers/Router/Tools'
import { verifyToken } from 'src/Actions/authentication'
import {
  getPasswordValidation,
  getFormOptionsWithValidation
} from 'src/Utils/FormUtils'
import formValidators from '../../Utils/formValidators'
import PasswordFactory from '../FormFactories/PasswordFactory'
import InputDefaultFactory from '../FormFactories/InputDefaultFactory'
import AuthBoxDoubleColumn from '../../UIComponents/AuthboxWithPass/AuthBox'
import Grid from '../../UIComponents/Grid/Grid'
import Label from '../../UIComponents/Label/Label'
import Button from '../../UIComponents/Button/Button'
import FormZ from '../../UIComponents/FormZ/FormZ'
import AuthStatusMessage from '../../UIComponents/AuthBox/AuthStatusMessage'
import PasswordValidation from '../../UIComponents/PasswordValidation/PasswordValidation'
import CreateUserTemplate from '../../UIComponents/TemplatesForm/CreateUserTemplate'

import {
  darkGray,
  bigText,
  largeMargin,
  margin
} from 'src/Styles/settings/Constants'
import AlertMessage from '../../UIComponents/AlertMessage/AlertMessage'

const CreateUser = () => {
  const dispatch = useDispatch()

  const {
    tokenValidateError,
    updatePassRequesting,
    updatePassSuccess,
    updatePassError
  } = useSelector(({ authentication, user }) => ({
    tokenValidateError: authentication.tokenValidateError,
    updatePassRequesting: user.updatePassRequesting,
    updatePassSuccess: user.updatePassSuccess,
    updatePassError: user.updatePassError
  }))

  const [showValidation, setShowValidation] = useState(false)
  const [formValues, setFormValues] = useState({
    username: '',
    password: '',
    passwordConfirmation: ''
  })

  useEffect(() => {
    const querystring = getQuerystring()
    setFormValues({
      ...formValues,
      username: querystring && querystring.u,
      token: querystring && querystring.t,
      userId: querystring && querystring.i
    })
    dispatch(verifyToken(querystring.t))
    dispatch(resetUser())
  }, [])

  const onChange = (values) => {
    setFormValues({
      ...formValues,
      ...values
    })
  }
  const passwordValidation = getPasswordValidation(
    formValues.password,
    formValues.passwordConfirmation
  )

  const onSubmit = () => {
    const isFormValid = t.validate(formValues, struct).isValid()
    const { token, userId, password } = formValues

    if (!passwordValidation.isValid) return

    if (isFormValid) {
      const passwordData = {
        new_password: password
      }
      dispatch(resetUser())
      dispatch(updateUserPassword(userId, passwordData, token))
    } else {
      setShowValidation(true)
    }
  }

  const options = {
    template: (locals) => CreateUserTemplate(locals),
    fields: {
      username: {
        label: 'E-mail',
        factory: InputDefaultFactory,
        autocomplete: false,
        config: {
          id: 'username',
          disabled: true
        }
      },
      password: {
        label: 'Senha',
        factory: PasswordFactory,
        autocomplete: false,
        config: {
          id: 'password',
          disabled: updatePassRequesting
        }
      },
      passwordConfirmation: {
        label: 'Confirmar Senha',
        factory: PasswordFactory,
        autocomplete: false,
        config: {
          id: 'passwordConfirmation',
          disabled: updatePassRequesting
        }
      }
    }
  }

  const struct = t.struct({
    username: formValidators.Email,
    password: formValidators.Password,
    passwordConfirmation: formValidators.Password
  })

  return (
    <Fragment>
      {tokenValidateError ? (
        <Redirect to='token-invalido?origin=criar-conta' />
      ) : updatePassSuccess ? (
        <AuthStatusMessage
          type='success'
          path='/login'
          labelButton='Fazer login'
          message='Perfeito! Conta criada com sucesso.'
        />
      ) : (
        <AuthBoxDoubleColumn focusTitle='Criar' title='conta'>
          <Grid.Col>
            <Label color={darkGray} fontSize={bigText} bold>
              PARA <Label fontSize={bigText}>ALTERAR SUA SENHA</Label> SIGA OS
              PASSOS ABAIXO:
            </Label>
            <PasswordValidation {...passwordValidation} />
          </Grid.Col>
          <Fragment>
            <Grid noPadding>
              <Grid.Row auto>
                <Label color={darkGray} fontSize={bigText} bold>
                  {`Olá, ${formValues.username ? formValues.username : ''}`}
                </Label>
              </Grid.Row>
              <Grid noPadding marginTop={largeMargin}>
                <Label color={darkGray} fontSize={bigText}>
                  Chegou a hora de criar sua conta. <br />É rápido e fácil.
                </Label>
              </Grid>
              {updatePassError && (
                <AlertMessage type='error' error={updatePassError} />
              )}
              <Grid noPadding marginTop={margin} marginBottom={margin}>
                <FormZ
                  options={
                    showValidation
                      ? getFormOptionsWithValidation(
                          options,
                          formValues,
                          struct
                        )
                      : options
                  }
                  struct={struct}
                  values={formValues}
                  onChange={onChange}
                  onSubmitForm={onSubmit}
                />
              </Grid>
              <Grid.Row>
                <Grid.Col offset={9} cols={7}>
                  <Button
                    type='submit'
                    id='buttonSubmit'
                    onClick={onSubmit}
                    disabled={updatePassRequesting}
                  >
                    Criar Conta
                  </Button>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Fragment>
        </AuthBoxDoubleColumn>
      )}
    </Fragment>
  )
}

export default CreateUser

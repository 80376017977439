import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import t from 'tcomb-form'

import SelectFactory from 'src/Components/FormFactories/SelectFactory'
import formValidators from 'src/Utils/formValidators'
import FormZ from 'src/UIComponents/FormZ/FormZ'
import { AccountTypes } from 'src/Constants/AccountTypes'
import AddBankAccountTemplate from 'src/UIComponents/TemplatesForm/AddBankAccountTemplate'
import { getFormOptionsWithValidation } from 'src/Utils/FormUtils'
import { createBankAccount } from 'src/Actions/bankAccounts'
import SelectBankCodeFactory from '../FormFactories/SelectBankCodeFactory'
import NumberFactory from '../FormFactories/NumberFactory'
import InputDefaultFactory from 'src/Components/FormFactories/InputDefaultFactory'
import DocumentFactory from 'src/Components/FormFactories/DocumentFactory'
import { MFASingleComponent } from '../MFA/mfaSingleComponent'

const AddBankAccountForm = ({ seller }) => {
  const INITIAL_VALUES = {
    type: '',
    bankCode: '',
    routingNumber: '',
    accountNumber: '',
    holderName: seller.type === 'business'
      ? `${seller.owner.first_name} ${seller.owner.last_name}`
      : `${seller.first_name} ${seller.last_name}`,
    document: seller.taxpayer_id || seller.ein
  }

  const dispatch = useDispatch()

  const [values, setValues] = useState(INITIAL_VALUES)
  const [showValidation, setShowValidation] = useState(false)
  const [showMFA, setShowMFA] = useState(false)

  const {
    createBankAccountSuccess,
    createBankAccountRequesting,
    banks,
    apiSettings
  } = useSelector(({ recipientMeans, banks, settings }) => ({ ...recipientMeans, ...banks, apiSettings: settings.items }))

  useEffect(() => {
    if (createBankAccountSuccess) {
      resetValues()
    }
  }, [createBankAccountSuccess])

  const formOptions = {
    template: locals => AddBankAccountTemplate(locals),
    fields: {
      type: {
        label: 'Tipo de Conta',
        factory: SelectFactory,
        config: {
          id: 'type',
          options: AccountTypes,
          placeholder: 'Selecione a conta'
        }
      },
      bankCode: {
        label: 'Banco',
        factory: SelectBankCodeFactory,
        autocomplete: false,
        config: {
          id: 'bank-code',
          options: banks
        }
      },
      routingNumber: {
        label: 'Agência',
        factory: NumberFactory,
        autocomplete: false,
        config: {
          id: 'routing-number',
          maxLength: 6
        }
      },
      routingCheckDigit: {
        label: 'Dígito',
        factory: InputDefaultFactory,
        autocomplete: false,
        config: {
          id: 'routing-check-digit',
          maxLength: 1
        }
      },
      accountNumber: {
        label: 'Conta Corrente',
        factory: NumberFactory,
        autocomplete: false,
        config: {
          id: 'account-number',
          maxLength: 20
        }
      },
      holderName: {
        label: 'Titular',
        factory: InputDefaultFactory,
        autocomplete: false,
        config: {
          id: 'holder-name'
        }
      },
      document: {
        label: 'CPF/CNPJ',
        factory: DocumentFactory,
        autocomplete: false,
        config: {
          id: 'taxpayer-id',
          disabled: !apiSettings.allowBankAccountCreationFromDifferentTaxpayerId
        }
      }
    }
  }

  const formStruct = t.struct({
    type: t.Object,
    bankCode: t.Object,
    routingNumber: formValidators.String,
    routingCheckDigit: t.maybe(t.String),
    accountNumber: formValidators.String,
    holderName: formValidators.String,
    document: formValidators.Document
  })

  const isFormValid = () => t.validate(values, formStruct).isValid()

  const submitForm = async () => {
    dispatch(createBankAccount(values, seller))
  }

  const handleSubmitForm = async () => {
    if (!isFormValid()) {
      setShowValidation(true)
    }
    setShowMFA(true)
  }

  const handleChangeValues = values => {
    setShowValidation(false)
    setValues(values)
  }

  const resetValues = () => setValues(INITIAL_VALUES)

  return (
    <>
      {showMFA && (
        <MFASingleComponent showComponent={setShowMFA} onSubmit={submitForm} />
      )}

      <FormZ
        options={showValidation
          ? getFormOptionsWithValidation(formOptions, values, formStruct)
          : formOptions}
        struct={formStruct}
        values={values}
        onChange={handleChangeValues}
        data-tracking={`CreateBankingAccount > Submit`}
        labelButton='Adicionar Conta'
        onSubmitForm={handleSubmitForm}
        isRequesting={createBankAccountRequesting}
      />
    </>
  )
}

AddBankAccountForm.propTypes = {
  seller: PropTypes.object.isRequired
}

export default AddBankAccountForm

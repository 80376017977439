import React, { useEffect, useState } from 'react'
import t from 'tcomb-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import styled from 'styled-components'

import { resetAuthError } from 'src/Actions/authentication'
import { getQuerystring, getRedirect } from 'src/Helpers/Router/Tools'
import formValidators from 'src/Utils/formValidators'
import InputDefaultFactory from '../FormFactories/InputDefaultFactory'
import PasswordFactory from '../FormFactories/PasswordFactory'
import { getFormOptionsWithValidation } from 'src/Utils/FormUtils'
import AlertMessage from '../../UIComponents/AlertMessage/AlertMessage'
import Button from '../../UIComponents/Button/ButtonDefault'
import Grid from '../../UIComponents/Grid/Grid'
import AuthBox from '../../UIComponents/AuthBox/AuthBox'
import LoginTemplate from '../../UIComponents/TemplatesForm/LoginTemplate'
import FormZ from '../../UIComponents/FormZ/FormZ'
import { MFASingleComponent } from '../MFA/mfaSingleComponent'
import { authenticate, checkCredentials } from '../../Actions/authentication'
import { signUp } from '../../Actions/twofa'

const LoginForm = () => {
  const dispatch = useDispatch()

  const { auth, isLoggedIn, authRequesting, checkCredentialsRequesting, checkCredentialsSuccess, checkCredentialsError } = useSelector(({ authentication, marketplace }) => ({
    auth: authentication,
    isLoggedIn: authentication.isLoggedIn,
    checkCredentialsRequesting: authentication.checkCredentialsRequesting,
    checkCredentialsSuccess: authentication.checkCredentialsSuccess,
    checkCredentialsError: authentication.checkCredentialsError,
    authRequesting: authentication.authRequesting,
    slug: marketplace.slug,
    isRequestingSlug: marketplace.isRequestingSlug
  }))

  const [redirect, setRedirect] = useState(null)
  const [showMFA, setShowMFA] = useState(false)

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    showValidation: false,
    disableSubmit: checkCredentialsRequesting || authRequesting
  })

  const onChange = ({ email, password }) => {
    setFormData({ ...formData, email, password })
  }

  const onSubmitForm = async () => {
    dispatch(resetAuthError())

    const { email, password } = formData

    if (validateForm(formProps)) {
      dispatch(checkCredentials(email, password))
    }
  }

  const values = {
    email: formData.email || '',
    password: formData.password || ''
  }

  const options = {
    template: locals => LoginTemplate(locals),
    fields: {
      email: {
        label: 'E-mail',
        factory: InputDefaultFactory,
        autocomplete: false,
        config: {
          id: 'email',
          disabled: checkCredentialsRequesting || authRequesting
        }
      },
      password: {
        label: 'Senha',
        factory: PasswordFactory,
        autocomplete: false,
        config: {
          id: 'password',
          disabled: checkCredentialsRequesting || authRequesting
        }
      }
    }
  }

  const struct = t.struct({
    email: formValidators.Email,
    password: formValidators.String
  })

  const formProps = {
    values,
    options: formData.showValidation
      ? getFormOptionsWithValidation(options, values, struct)
      : options,
    struct,
    onSubmitForm,
    onChange,
    'data-tracking': 'LoginForm'
  }

  const validateForm = ({ values, struct }) => {
    const isValid = t.validate(values, struct).isValid()
    if (!isValid) setFormData({ showValidation: true, ...formData })
    return isValid
  }

  useEffect(() => {
    if (checkCredentialsSuccess) {
      dispatch(signUp(formData.email))
      setShowMFA(true)
    }
  }, [checkCredentialsSuccess])

  useEffect(() => {
    const querystring = getQuerystring()

    setFormData({ email: querystring.u })

    const loadRedirect = async () => {
      if (isLoggedIn) {
        let res = await getRedirect()
        setRedirect(res || '/')
      }
    }

    loadRedirect()
  }, [isLoggedIn])

  if (showMFA) {
    return <MFASingleComponent
      email={formData.email}
      showComponent={() => setShowMFA(false)}
      onSubmit={() => dispatch(authenticate(formData.email, formData.password))}
    />
  }

  return redirect
    ? <Redirect to={redirect} />
    : (
      <AuthBox title={<><strong>Acesse</strong> sua conta</>}>
        <Grid noPadding>
          {
            auth && checkCredentialsError && !auth.tokenValidateError &&
            <div style={{ marginBottom: '3rem' }} data-test='auth-error'>
              <AlertMessage
                type={'error'}
                noMargin
                noCode
                error={checkCredentialsError}
              />
            </div>
          }
          <Grid.Row auto>
            <FormZ {...formProps}>
              <Grid.Row auto style={{ marginTop: '4rem' }}>
                <ContainerButtons>
                  <Link to={`/esqueci-minha-senha?${formData.email ? 'u=' + formData.email : ''}`}>
                    <Button type='button' onClick={
                      () => dispatch(resetAuthError())
                    } link disabled={checkCredentialsRequesting || authRequesting}>
                    Esqueci minha senha
                    </Button>
                  </Link>
                  <ButtonLogin
                    type='submit'
                    disabled={formData.disableSubmit}
                    isLoading={checkCredentialsRequesting || authRequesting}
                  >
                    Entrar
                  </ButtonLogin>
                </ContainerButtons>
              </Grid.Row>
            </FormZ>
          </Grid.Row>
        </Grid>
      </AuthBox>
    )
}

const ContainerButtons = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
`

const ButtonLogin = styled(Button)`
  width: 110px;
`

export default LoginForm

import React from 'react'
import { useSelector } from 'react-redux'

import BadgeComponentUI from 'src/UIComponents/BadgeComponent/BadgeComponent'

const BadgeComponent = () => {
  const { sellerStatus } = useSelector(({ context }) => {
    return {
      sellerStatus: context.seller?.status
    }
  })
  return (
    <BadgeComponentUI status={sellerStatus} />
  )
}

export default BadgeComponent

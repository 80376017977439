import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles/AuthForm.scss'
import Loader from '../Loader/Loader'
import * as authActions from '../../Actions/authentication'
import * as userActions from '../../Actions/user'
import { getQuerystring } from '../../Helpers/Router/Tools'

const mapStateToProps = (state /*, ownProps */) => {
  const { logo } = state.custom
  const { id: marketplaceId } = state.marketplace
  const { permissions } = state.user
  const {
    confirmUserRequesting,
    confirmUserSuccess,
    confirmUserError,
    confirmUserErrorMessage
  } = state.authentication

  return {
    auth: state.authentication,
    logo,
    permissions,
    marketplaceId,
    confirmUserRequesting,
    confirmUserSuccess,
    confirmUserError,
    confirmUserErrorMessage
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...authActions,
      ...userActions
    },
    dispatch
  )

class LoginForm extends Component {
  constructor(props) {
    super(props)

    const querystring = getQuerystring()

    if (
      window.location.pathname === '/access' &&
      querystring.u &&
      querystring.t
    ) {
      this.props.supportAuthenticate(querystring.u, querystring.t)
    }
  }

  render() {
    const { auth, logo, marketplaceId } = this.props

    return (
      /* TODO - Put this in some UI Component */
      <div className='container-fluid auth whitelabel-primary-bgcolor'>
        <div className='content col-xs-10 col-sm-10 col-md-6 col-lg-5 col-xl-4 m-auto'>
          <form
            name='form'
            className='auth-card-form'
            onSubmit={(e) => this.onSubmit(e, marketplaceId)}
          >
            <div className='auth-card-form-header'>
              <img alt='logo' src={logo} />
              <span className='title text-center'>Acessando Dash</span>
            </div>
            <div className='auth-card-form-body'>
              {auth.authError && (
                <div className='alert alert-danger'>
                  <div className='alert-text'>{auth.authError}</div>
                </div>
              )}
              <div className='auth-card-form-actions'>
                {auth.authRequesting && (
                  <Loader position='center' size='small' color='#646c9a' />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

LoginForm.propTypes = {
  auth: PropTypes.object,
  logo: PropTypes.string,
  marketplaceId: PropTypes.string,
  supportAuthenticate: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)

import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DateLoader from 'src/Components/SaleDetail/DateLoader'
import { panelStore } from 'src/Store/panelStore'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'
import Grid from 'src/UIComponents/Grid/Grid'
import styled from 'styled-components'
import {
  getBillingPayment,
  getBillingPaymentReceipt
} from '../../Actions/billing-payments'
import ButtonDefault from '../../UIComponents/Button/ButtonDefault'
import createToast from '../../Utils/createToast'
import { getPaymentTypeIcon, parseDate, toMoney } from '../../Utils/Utils'

export default function BillingPaymentsReceipt({ authorizationId }) {
  const dispatch = useDispatch()
  const [error, setError] = useState(null)

  const {
    getBillingPaymentRequesting,
    billingPayment,
    getBillingPaymentError,
    logo,
    themeColor,
    seller
  } = useSelector(({ billingPayments, custom, context }) => ({
    ...billingPayments,
    logo: custom.logo,
    themeColor: custom.color,
    seller: context.seller
  }))

  useEffect(() => {
    dispatch(getBillingPayment(authorizationId))
  }, [authorizationId])

  useEffect(() => {
    if (!getBillingPaymentError) return

    if (getBillingPaymentError?.message) {
      setError({
        icon:
          getBillingPaymentError.message?.icon ||
          '/assets/icons/alerts/alert.svg',
        title:
          getBillingPaymentError.message?.title ||
          'Desculpe, ocorreu um erro ao carregar o detalhe desta venda.',
        description:
          getBillingPaymentError.message?.description ||
          'Tente novamente mais tarde.'
      })
    }

    if (getBillingPaymentError?.status === 404) {
      setError({
        icon: '/assets/icons/alerts/alert.svg',
        title: 'Desculpe, o detalhe desta venda não foi encontrado.',
        description: 'Erro 404'
      })
    }
  }, [getBillingPaymentError])

  const handleGetBillingPaymentReceipt = async () => {
    const { payment_id: paymentId } = billingPayment

    try {
      const getState = () => panelStore.getState()
      const PDFStream = await getBillingPaymentReceipt(paymentId, getState)
      if (PDFStream) {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([PDFStream]))
        link.target = '_blank'
        link.download = `comprovante-pagamento-${paymentId}.pdf`
        document.body.append(link)
        link.click()
        link.remove()
        createToast(
          'success',
          'O comprovante de pagamento foi gerado com sucesso!'
        )
      } else {
        createToast(
          'error',
          'Não foi possível gerar o comprovante de pagamento. Tente novamente mais tarde.'
        )
      }
    } catch (error) {
      createToast(
        'error',
        'Ocorreu um erro ao tentar gerar o comprovante de pagamento.'
      )
    }
  }

  return (
    <Grid.Row auto>
      {getBillingPaymentRequesting ? <DateLoader /> : null}

      {getBillingPaymentError ? (
        <>
          <br />
          <br />
          <br />
          <br />
          {error != null && (
            <EmptyState
              titleSize='big'
              iconSrc={error.icon}
              title={error.title}
              description={error.description}
            />
          )}
        </>
      ) : null}

      {billingPayment ? (
        <TicketInfos>
          <StyledGrid themeColor={themeColor}>
            <Grid.Col className='ticket__info__header p__32' auto>
              <Logo src={logo} themeColor={themeColor} />
              <h3 className='theme__color__text'>
                <strong>Comprovante de pagamento</strong>
              </h3>
              <h5>
                {parseDate(
                  billingPayment.operation.date,
                  'DD [de] MMMM [de] YYYY [às] HH:mm'
                )}
              </h5>
            </Grid.Col>

            <Grid.Row className='ticket__info__section' auto>
              <Grid.Col>
                <p>Valor</p>
                <h4>
                  <strong>{toMoney(billingPayment.amount)}</strong>
                </h4>
              </Grid.Col>
              <Grid.Col>
                <p>Tipo</p>
                <div className='ticket__info__section__boleto' auto>
                  <h4>
                    <strong>Pagamento</strong>
                  </h4>
                  <img
                    src={getPaymentTypeIcon('boleto')}
                    alt='ícone de boleto'
                  />
                </div>
              </Grid.Col>
            </Grid.Row>
            <Grid.Col className='p10'>
              <p>Código de Retorno</p>
              <h4>{billingPayment.id}</h4>
            </Grid.Col>
            <hr className='separator separator--dotter' />
            <Grid.Col className='ticket__data__section p10' auto>
              <h3 className='ticket__data__section__title '>
                <strong>Dados</strong> do documento
              </h3>
              <Grid.Col className='ticket__data__section__item'>
                <p>Nome do favorecido</p>
                <h4>{billingPayment.recipient}</h4>
              </Grid.Col>
              <Grid.Col className='ticket__data__section__item'>
                <p>{seller.taxpayer_id ? 'CPF :' : 'CNPJ :'}</p>
                <h4>
                  {seller.taxpayer_id
                    ? seller.taxpayer_id.toCPF()
                    : seller.ein?.toCnpj()}
                </h4>
              </Grid.Col>
              <Grid.Col className='ticket__data__section__item boleto'>
                <p>Código do boleto : </p>
                <h4>{billingPayment.bar_code}</h4>
              </Grid.Col>
            </Grid.Col>
            <Grid marginBottom='20px'>
              <ButtonDefault onClick={() => handleGetBillingPaymentReceipt()}>
                Gerar Comprovante
              </ButtonDefault>
            </Grid>
          </StyledGrid>
        </TicketInfos>
      ) : null}
    </Grid.Row>
  )
}

BillingPaymentsReceipt.propTypes = {
  authorizationId: PropTypes.object.isRequired
}

const Logo = styled.img`
  max-height: 42px;
  max-width: 112px;
  margin: auto;
  color: ${(props) => props.themeColor};
`

const TicketInfos = styled.div`
  background: white;
  min-height: 200px;
  position: relative;
  padding: 10px 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  .separator {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    width: 100%;
  }

  .separator--dotter {
    border-bottom: 0.25rem dotted #c4c4c4;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 29px;
    right: 0;
    bottom: -24px;
    left: 0;
    background: linear-gradient(-45deg, transparent 70%, white 50%),
      linear-gradient(45deg, transparent 70%, white 50%);
    background-repeat: repeat-x;
    background-size:
      25px 20px,
      25px 20px;
    background-position: center;
  }
`
const StyledGrid = styled(Grid)`
  padding: 0 2.5rem;

  .theme__color__text {
    color: ${(props) => props.themeColor};
  }

  .ticket__info__header {
    text-align: center;
  }

  .ticket__info__section {
    margin-top: 1rem;
    padding-left: 1rem;

    div {
      text-aling: left;
    }
  }

  .ticket__info__section__boleto {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    h4 {
      margin-bottom: 0;
    }
  }

  .p__32 {
    padding: 4rem;
  }

  .p10 {
    padding: 10px;
  }

  .ticket__data__section {
    margin-top: 20px;
    padding-bottom: 4rem;

    .ticket__data__section__title {
      color: ${(props) => props.themeColor};
    }

    .ticket__data__section__item {
      margin-top: 1.25rem;
    }

    .boleto {
      word-break: break-all;
    }
  }
`

import React from 'react'
import * as PropTypes from 'prop-types'
import UIBankAccountIcon from 'src/UIComponents/BankAccountIcon/UIBankAccountIcon'
import { useSelector } from 'react-redux'

const BankAccountIcon = ({ bankCode }) => {
  const { banks } = useSelector(({ banks }) => banks)
  const bank = bankCode && banks.find(bank => bank.code === bankCode)

  if (bank) return <UIBankAccountIcon name={bank.name} src={`/assets/icons/banks/${+bank.code || bank.code}.svg`} />

  return null
}

BankAccountIcon.propTypes = {
  bankCode: PropTypes.string
}

export default BankAccountIcon

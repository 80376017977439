import React, { useState, Fragment, useEffect } from 'react'
import styled from 'styled-components'

import Button from '../../UIComponents/Button/ButtonDefault'
import AuthBox from '../../UIComponents/AuthBox/AuthBox'
import Grid from '../../UIComponents/Grid/Grid'
import FormZ from '../../UIComponents/FormZ/FormZ'
import InputDefaultFactory from '../FormFactories/InputDefaultFactory'
import t from 'tcomb-form'
import formValidators from '../../Utils/formValidators'
import { Link, Redirect } from 'react-router-dom'
import { recoverAccess } from '../../Actions/authentication'
import { useDispatch } from 'react-redux'
import RedirectMessage from '../../UIComponents/Authentication/RedirectMessage'
import { getQuerystring } from '../../Helpers/Router/Tools'
import Label from 'src/UIComponents/Label/Label'

import { darkerGray, bigText } from 'src/Styles/settings/Constants'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const [ redirect, setRedirect ] = useState(true)
  const [ backToLogin, setBackToLogin ] = useState(null)
  const [ formData, setFormData ] = useState({
    email: ''
  })

  const onChange = ({ email }) => {
    setFormData({ email })
  }

  const formOptions = {
    values: {
      email: formData.email
    },
    options: {
      fields: {
        email: {
          label: 'E-mail',
          factory: InputDefaultFactory,
          autocomplete: true,
          config: {
            id: 'email'
          }
        }
      }
    },
    struct: t.struct({
      email: formValidators.Email
    }),
    onChange
  }

  useEffect(() => {
    const querystring = getQuerystring()
    if (querystring.u) {
      const email = querystring.u
      setFormData({ email })
    }
  }, [])

  const sendForm = () => {
    setRedirect(false)
    dispatch(recoverAccess(formOptions.values.email))
  }

  return backToLogin ? <Redirect to={'/'} />
    : (
      <AuthBox title={<><strong>Esqueci</strong> a senha</>}>
        <Grid noPadding>
          { redirect
            ? (
              <Fragment>
                <Grid.Row auto>
                  <Label fontSize={bigText} color={darkerGray}>Confirmar e-mail de acesso</Label>
                </Grid.Row>
                <Grid.Row auto>
                  <FormZ {...formOptions} />
                </Grid.Row>
                <Grid.Row style={{ marginTop: '4rem' }}>
                  <Grid.Col cols={7}>
                    <Link to={`/login${formData.email ? '?u=' + formData.email : ''}`}>
                      <Button
                        id={'backToLoginButton'}
                        type='button'
                        link>
                        Voltar para o login
                      </Button>
                    </Link>
                  </Grid.Col>
                  <Grid.Col offset={2} cols={7}>
                    <Button
                      id={'sendButton'}
                      onClick={sendForm} >Confirmar e-mail</Button>
                  </Grid.Col>
                </Grid.Row>
              </Fragment>
            ) : (
              <Fragment>
                <Grid.Row auto>
                  <RedirectMessage message={'Agora é a parte mais fácil, basta checar sua caixa de email.'} />
                </Grid.Row>
                <WrapperButton auto>
                  <ButtonLogin
                    id={'backToLoginButton'}
                    onClick={() => setBackToLogin(true)}
                  >
                    Voltar para o login
                  </ButtonLogin>
                </WrapperButton>
              </Fragment>
            )}
        </Grid>
      </AuthBox>
    )
}

const WrapperButton = styled(Grid.Row)`
  display: flex;
  justify-content: center;
`

const ButtonLogin = styled(Button)`
  width: 164px;
`

export default ForgotPassword

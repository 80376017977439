import React, { useEffect } from 'react'
import BalanceSellerUI from 'src/UIComponents/BalanceSeller/BalanceSeller'
import { useDispatch, useSelector } from 'react-redux'

import { getBalanceStats } from 'src/Actions/balances'
import { getBlockedBalanceStatsFromGetBalances } from '../../Actions/balances'
const BalanceSeller = ({ shortView }) => {
  const {
    currentBalance,
    blockedBalance,
    sellerId,
    currentBalanceRequesting,
    blockedBalanceRequesting,
    balancesErrors,
    currentBalanceError,
    blockedBalanceError
  } = useSelector(({ balances, context }) => {
    return {
      currentBalance: balances.currentBalance,
      blockedBalance: balances.blockedBalance,
      balancesErrors: balances.balancesErrors,
      currentBalanceError: balances.currentBalanceError,
      blockedBalanceError: balances.blockedBalanceError,
      currentBalanceRequesting: balances.currentBalanceRequesting,
      blockedBalanceRequesting: balances.blockedBalanceRequesting,
      sellerId: context.seller?.id
    }
  })
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBalanceStats())

    if (!shortView) {
      dispatch(getBlockedBalanceStatsFromGetBalances('payments', {}))
    }
  }, [sellerId])

  return (
    <>
      <BalanceSellerUI
        shortView={shortView}
        balancesErrors={balancesErrors}
        currentBalanceError={currentBalanceError}
        blockedBalanceError={blockedBalanceError}
        currentBalanceRequesting={currentBalanceRequesting}
        blockedBalanceRequesting={blockedBalanceRequesting}
        currentBalance={currentBalance}
        blockedBalance={blockedBalance}
      />
    </>
  )
}

export default BalanceSeller

import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import Grid from 'src/UIComponents/Grid/Grid'
import HeaderSidebar from 'src/UIComponents/HeaderSidebar/HeaderSidebar'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import BillingPaymentsReceipt from './BillingPaymentsReceipt'

const BillingDetail = ({ handleClose, authorizationId }) => {
  return (
    <Fragment>
      <HeaderSidebar
        title={
          <>
            <strong>Comprovante</strong> de pagamento
          </>
        }
        handleClose={handleClose}
      />
      <SidebarContainer>

        <Grid.Row auto>
          <BillingPaymentsReceipt authorizationId={authorizationId} />
        </Grid.Row>
      </SidebarContainer>
    </Fragment>
  )
}

BillingDetail.propTypes = {
  handleClose: PropTypes.func,
  authorizationId: PropTypes.string
}

export default BillingDetail

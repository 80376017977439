import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch, useHistory } from 'react-router-dom'

import { resetReceipt } from 'src/Actions/receipt'
import Sidebar from 'src/UIComponents/Sidebar/Sidebar'
import BillingDetail from './BillingDetail'

const BillingPaymentsDetail = () => {
  const history = useHistory()

  const matchFromBalancesList = useRouteMatch({
    path: '*/extrato/pagamento-de-conta/:id'
  })

  const [showSidebar, setShowSidebar] = useState(!!matchFromBalancesList)
  const [authorizationId, setAuthorizationId] = useState()

  const dispatch = useDispatch()

  useEffect(() => {
    if (matchFromBalancesList) {
      if (matchFromBalancesList.params['id'] !== authorizationId) {
        setAuthorizationId(matchFromBalancesList.params['id'])
        setTimeout(() => {
          setShowSidebar(true)
        }, 500)
      }
    } else {
      setShowSidebar(false)
      setTimeout(() => {
        setAuthorizationId(null)
      }, 500)
    }
  }, [matchFromBalancesList])

  useEffect(() => {
    return () => dispatch(resetReceipt())
  }, [])

  const handleClose = () => {
    setShowSidebar(false)

    setTimeout(() => {
      setAuthorizationId(null)
      const urlSplitted = matchFromBalancesList.url.split('/')
      history.push(`/${urlSplitted[1]}/${urlSplitted[2]}/${urlSplitted[3]}/`)
    }, 500)

    dispatch(resetReceipt())
  }

  return (
    <>
      {matchFromBalancesList && authorizationId && (
        <Sidebar show={showSidebar}>
          <BillingDetail handleClose={handleClose} authorizationId={authorizationId} />
        </Sidebar>
      )}
    </>
  )
}

export default BillingPaymentsDetail

import React from 'react'

import CardConfig from '../../UIComponents/CardConfig/CardConfig'
import Links from '../../Containers/Navigation/ConfigMenu'
import { useGetPermissionProfile } from '../../Hooks/useGetPermissionProfile'

const CardConfigComponent = () => {
  const { getProfile } = useGetPermissionProfile()
  const profile = getProfile()

  return (
    <CardConfig profile={profile} links={Links} />
  )
}

export default CardConfigComponent
